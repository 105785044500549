<template>
  <v-container class="mt-8">
    <v-row justify="center">
      <v-col md="8">
        <v-card flat outlined>
          <v-row>
            <v-col class="pb-0 mx-4" md="4"
              ><v-text-field class="mt-2" v-model="name" outlined></v-text-field
            ></v-col>
          </v-row>
          <v-row justify="start" class="ma-0 mt-2">
            <v-col>
              <v-data-table :dark="$store.state.isDarkMode"
                :headers="headers"
                :items="names"
                item-key="id"
                @click:row="filternames"
                hide-default-footer
                style="
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  cursor: pointer;
                "
                :loading="loading"
              ></v-data-table>
            </v-col>
            <v-col>
              <v-data-table :dark="$store.state.isDarkMode"
                :headers="selectedHeaders"
                :items="selectedNames"
                item-key="id"
                hide-default-footer
                style="border: 1px solid #ccc; border-radius: 4px"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.name }}</td>
                    <td>
                      <v-icon color="red" @click="RemoveSelectedItem(item)"
                        >mdi-delete</v-icon
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <v-row justify="center" class="mt-6">
          <v-col cols="2">
            <v-btn color="primary" class="save-button" @click="submit()">{{
              $t("save")
            }}</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn @click="cancel" color="error" class="cancel-button">{{
              $t("cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  async created() {
    await axios.get("/hr/employee").then((res) => {
      this.names = res.data.data;
      this.loading = false;
    });
    axios.get(`/hr/group/${this.$route.params.id}`).then((res) => {
      this.name = res.data.data.name;
      for (let i = 0; i < res.data.data.data.length; i++) {
        this.filternames(res.data.data.data[i]);
      }
      this.loading = false;
    });
  },
  data() {
    return {
      name: this.$route.params.id,
      headers: [{ text: "Name", value: "name" }],
      selectedHeaders: [{ text: "Selected Name", value: "name" }],
      names: [],
      selectedNames: [],
      loading: true,
    };
  },
  methods: {
    cancel() {
      this.$router.push("/HR/initiate");
    },
    filternames(item) {
      for (let i = 0; i < this.names.length; i++) {
        if (this.names[i].name == item.name) {
          this.names.splice(i, 1);
        }
      }
      this.selectedNames.push(item);
    },
    RemoveSelectedItem(item) {
      for (let i = 0; i < this.selectedNames.length; i++) {
        if (this.selectedNames[i] == item) {
          this.selectedNames.splice(i, 1);
        }
      }
      this.names.push(item);
    },
    submit() {
      for (let i = 0; i < this.selectedNames.length; i++) {
        this.selectedNames[i] = { id: this.selectedNames[i].id };
      }
      console.log(this.selectedNames);
      axios
        .put(`/hr/group/${this.$route.params.id}`, {
          name: this.name,
          employees: this.selectedNames,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$Notifications(
              "تم تعديل مجموعة جديدة بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
          }
          this.$router.push("/HR/initiate");
        });
    },
  },
};
</script>